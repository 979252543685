import React from 'react';
import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import Footer from "./hooks/Footer";

const Law = () => {
  return (
   <>
   <Navbar />
      <div className="box1 overflow-hidden">
        <section class="the_law">
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#FF0102]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#FF0102]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[3rem] text-[2rem]">
                    Legal Assistance for Money Recovery
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className='mx-auto overflow-hidden md:max-w-4xl'> 
        <div className='pt-5'>
          <p className='text-center'>At Haisty Decrypt, we understand the unique challenges that victims face when attempting to recover money lost to scams. Our comprehensive legal services are designed to provide tailored solutions and guide you through the intricate process of financial recovery. Our dedicated team of experienced attorneys is committed to securing the best possible outcome for our clients. Our legal services encompass</p>
        </div>
      </div>

      <div className='mx-auto overflow-hidden md:max-w-7xl'>
        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
            <motion.div className='bg-gren-600 h-fit law_shadow p-5'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className='flex gap-3'>
                  <Avatar style={{ height: "50px", width: "50px", background: "#F3A8A8",  border: "solid 1px red" }}>
                      <img src="strategy-development.png" alt="" className='w-[30px]'/>
                  </Avatar>

                  <div className='pt-3'>
                    <p className='font-semibold'>Case Evaluation and Strategy</p>
                  </div>
                </div>

                <div className='pt-5'>
                 <span className='font-semibold'>•</span> Conducting a meticulous assessment of your case to understand its nuances
                </div>

                <div className='pt-2'>
                 <span className='font-semibold'>•</span> Developing a personalized legal strategy crafted to maximize the likelihood of successful recovery.
                </div>
            </motion.div>

            <motion.div className='bg-gren-600 h-full law_shadow p-5'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className='flex gap-3'>
                  <Avatar style={{ height: "50px", width: "50px", background: "#F3A8A8",  border: "solid 1px red" }}>
                      <img src="agreement.png" alt="" className='w-[30px]'/>
                  </Avatar>

                  <div className='pt-3'>
                    <p className='font-semibold'>Negotiations with Scam Entities</p>
                  </div>
                </div>

                <div className='pt-5'>
                 <span className='font-semibold'>•</span> Engaging in strategic negotiations with the involved parties on your behalf.
                </div>

                <div className='pt-2'>
                 <span className='font-semibold'>•</span> Utilizing our expertise to navigate complex negotiations and secure optimal settlements.
                </div>
            </motion.div>
            <motion.div className='bg-gren-600 h-full law_shadow p-5'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className='flex gap-3'>
                  <Avatar style={{ height: "50px", width: "50px", background: "#F3A8A8",  border: "solid 1px red" }}>
                      <img src="compliant.png" alt="" className='w-[30px]'/>
                  </Avatar>

                  <div className='pt-3'>
                    <p className='font-semibold'>Legal Representation</p>
                  </div>
                </div>

                <div className='pt-5'>
                 <span className='font-semibold'>•</span> Providing steadfast legal representation throughout all stages of the recovery process.
                </div>

                <div className='pt-2'>
                 <span className='font-semibold'>•</span> Offering advocacy and support to ensure your rights are protected and upheld.
                </div>
            </motion.div>
            <motion.div className='bg-gren-600 h-full law_shadow p-5'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className='flex gap-3'>
                  <Avatar style={{ height: "50px", width: "50px", background: "#F3A8A8",  border: "solid 1px red" }}>
                      <img src="asset-management.png" alt="" className='w-[30px]'/>
                  </Avatar>

                  <div className='pt-3'>
                    <p className='font-semibold'>Asset Tracing and Recovery</p>
                  </div>
                </div>

                <div className='pt-5'>
                 <span className='font-semibold'>•</span> Identifying and tracing assets linked to the fraudulent activities.
                </div>

                <div className='pt-2'>
                 <span className='font-semibold'>•</span> Implementing effective strategies to recover funds by targeting the source of the scam.
                </div>
            </motion.div>
            <motion.div className='bg-gren-600 h-full law_shadow p-5'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className='flex gap-3'>
                  <Avatar style={{ height: "50px", width: "50px", background: "#F3A8A8",  border: "solid 1px red" }}>
                      <img src="microscope-science.png" alt="" className='w-[30px]'/>
                  </Avatar>

                  <div className='pt-3'>
                    <p className='font-semibold'>Financial Forensic Analysis</p>
                  </div>
                </div>

                <div className='pt-5'>
                 <span className='font-semibold'>•</span> Conducting a meticulous assessment of your case to understand its nuances
                </div>

                <div className='pt-2'>
                 <span className='font-semibold'>•</span> Leveraging forensic techniques to gather evidence that strengthens your legal case.
                </div>
            </motion.div>
            <motion.div className='bg-gren-600 h-fit law_shadow p-5'
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className='flex gap-3'>
                  <Avatar style={{ height: "50px", width: "50px", background: "#F3A8A8",  border: "solid 1px red" }}>
                      <img src="partners.png" alt="" className='w-[30px]'/>
                  </Avatar>

                  <div className='pt-3'>
                    <p className='font-semibold'>Collaboration with Regulatory Bodies</p>
                  </div>
                </div>

                <div className='pt-5'>
                 <span className='font-semibold'>•</span> Collaborating with relevant regulatory bodies and authorities to escalate the legal process.
                </div>

                <div className='pt-2'>
                 <span className='font-semibold'>•</span> Ensuring that your case is pursued within the framework of applicable laws and regulations.
                </div>
            </motion.div>
        </div>
      </div>

 <div>
 <Footer/>
 </div>
   
   </>
  )
}

export default Law