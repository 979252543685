
import React from 'react';
import Navbar from './Navbar';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const Bitcoin = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const fabWhiteStyle = {
    color: 'common.white',
    bgcolor: '#29d090',
    '&:hover': {
      color: '#29d090', // Change the color on hover
      bgcolor: 'transparent',
    },
  };

  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const transitionDuration = 500;

  const fabs = [
    {
      color: 'primary',
      sx: fabStyle,
      icon: <StarIcon />, // Icon for Excellence
      label: 'Excellence',
    },
    {
      color: 'secondary',
      sx: fabStyle,
      icon: <SecurityIcon />, // Icon for Integrity
      label: 'Integrity',
    },
    {
      color: 'inherit',
      sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
      icon: <GroupIcon />, // Icon for Teamwork
      label: 'Teamwork',
    },
  ];

  return (
    <>
    <Progress/>
      <Navbar />
      <div className="box1 overflow-hidden">
        <section class="Phone">
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#29d090]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#29d090]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[4rem] text-[2rem]">
                     Phone/ Device Unlock
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="bg-[#e5e8e8] h-fit">
        <div className="mx-auto overflow-hidden  md:max-w-7xl">
          <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
            <div className="bg-[reen] h-fit">
              <div>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}>
                Navigating the realm of technology can often present a formidable challenge, and encountering locked devices due to potential threats can evoke a profound sense of frustration. However, rest assured, for we now introduce an optimal solution that will set your mind at ease. Witness uninterrupted access to your cherished devices, unfettered by carrier restrictions, and bid farewell to the vexation that accompanies device lockouts. Our highly skilled team is primed to ensure a seamless unlocking process, endowing you with the pleasure of swift device utilization. Bestow upon us your trust, and we shall deliver effective and reliable unlocking services, empowering you to reclaim mastery and fully harness the potential of your prized devices.
                </motion.p> <br /> <br />
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.7, duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}>
                Within our innovative hub, we possess a deep understanding of the intricacies of contemporary technology, and our cutting-edge methodologies guarantee a seamless unlocking experience. The inconvenience of device lockouts shall no longer afflict you. Our ingenious solutions cater to a wide spectrum of devices, ensuring perpetual liberation. Backed by a multitude of satisfied clients and decades of industry expertise, we proudly stand as the foremost authorities in the field of unlocking services.
                </motion.p>
              </div>
            </div>
            <div className="bg-[reen] h-fit">
              <motion.div className="flex justify-center "
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                }}>
                <img src="https://image.cnbcfm.com/api/v1/image/103962017-GettyImages-485204228.jpg?v=1529472761" alt=""
                  className="rounded-lg img__bord" />
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <motion.div className="pt-[6%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <div className="flex justify-center">
          <p className="font-semibold text-[#FF0102]">Core Values</p>
        </div>
      </motion.div>

      <motion.div className="flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <p className="text-[46px] font-semibold text-center">Why Choose Us</p>
      </motion.div>


      <div className="mx-auto overflow-hidden  md:max-w-7xl">
            <div className="grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                <div className="bg-green-00 h-fit">
                    <div className="flex justify-center">
                        <img src="https://image.cnbcfm.com/api/v1/image/106925470-1628697893527-computer-programming-web-development-coding-app-development-application-development-science-computer_t20_7yLpWv.jpg?v=1628698099" alt=""
                        className="rounded-md"/>
                    </div>
                </div>
                <div className="bg-green-00 h-fit">
                <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
          position: 'relative',
          minHeight: 200,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
           value={value}
           onChange={handleChange}
           indicatorColor="#FF0102" // Change this to "#29d090"
           textColor="#FF0102"
           variant="fullWidth"
           aria-label="action tabs example"
           sx={{ "& .MuiTabs-indicator": { backgroundColor: "#FF0102" } }} 
          >
            <Tab label="Expertise" {...a11yProps(0)} />
            <Tab label="Peace" {...a11yProps(1)} />
            <Tab label="Reputation" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <p className='font-bold'>Expertise and Experience:</p>
          </div>
          <div className='pt-3'>
          Our team of seasoned professionals boasts an extensive reservoir of knowledge and a wealth of hands-on experience within the intricate realm of phone and device unlocking. With years of dedicated service in this industry, we navigate the intricacies with a finesse that only time and expertise can cultivate.
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <p className='font-bold'>Peace of Mind</p>
          </div>
          <div className='pt-3'>
          Unlocking your device can be a transformative experience, enhancing its functionality and unveiling new opportunities. We offer the reassurance that comes from knowing your device is entrusted to capable hands, and that your confidential data is handled with the utmost discretion and care.
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <p className='font-bold'>Trusted Reputation</p>
          </div>
          <div className='pt-3'>
          In the field of device unlocking, we have forged an unshakable reputation founded on trust. Our portfolio proudly showcases a roster of satisfied clients who wholeheartedly endorse our reliability and professionalism. Our proven track record speaks volumes about our unwavering commitment to delivering excellence in every aspect of our service.
          </div>
        </TabPanel>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </Box>
    </Container>
                    <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Expertise</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
               </div>
                </div>
            </div>

       
      </div>


      <div className="bg-[#e5e8e8] h-fit mt-5">
        <motion.div className="pt-[6%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <div className="flex justify-center">
          <p className="font-semibold text-[#FF0102]">Key Services</p>
        </div>
      </motion.div>

      <motion.div className="flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <p className="text-[46px] font-semibold">Our Services</p>
      </motion.div>




      <div className="mx-auto overflow-hidden  md:max-w-7xl">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="responsive.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">iMac, Mac Book <br/> & iPad Unlock</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="android.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Android<br/>Unlock/Hack</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.4, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="smartphone.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">iPhone<br/>Hack/Unlcok</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.5, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="apple.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">iCloud<br/>Unlock</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="sim-card.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Sim Card<br/> Cloning</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.7, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="shield0.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">24 Hrs<br/>Protect</p>
                        </div>
                    </motion.div>
                    {/* <div className="bg-green-600 h-20">

                    </div>
                    <div className="bg-green-600 h-20">

                    </div> */}
                </div>
            </div>


            <section class="Contact__con mt-2">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-1 pb-10 pt-[5%]">
                     <div className='bggreen-600 h-fit'>
                        <div className='xl:pl-5 pl-2'>
                            <p className='font-bold xl:text-[1.8rem] text-[1.8rem]'> DO YOU WANT TO TALK TO US?</p>
                        </div>

                        <div className='xl:pl-5 pl-2'>
                            <p>
                            Have you lost something valuable or need assistance recovering lost assets? We're here to support you every step 
                            of the way. Feel free to reach out to us anytime – your concerns and inquiries matter to us, and we're dedicated 
                            to helping you regain what's rightfully yours!
                            </p>
                        </div>

                     </div>
                     <div className='bg-green-00 h-fit'>
                            <div className='xl:flex xl:justify-center flex justify-start mt-10'>
                            <button className='buttonx'>Contact Us</button>
                            </div>
                     </div>
                  </div>
               </div>
            </div>
        </section>

        </div>


        <div>
            <Footer/>
        </div>
    </>
  )
}

export default Bitcoin;
