
import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const AppManagementReview = () => {

    var settings = {
        dots: false,
        infinite: true,
          autoplay: false,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className="carouselx h-fit" >
      
    <Slider {...settings} className="mb-[0%]" id="Divx">
        
        <div className="box1 overflow-hidden">
            <section class="herox">
                <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden mt-[30%]'>
                                <div>
                                    <p className='text-white'>Your Reliable Asset Retrieval Ally</p>
                                </div>

                                <div>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Skilled Crew & <br/>State-of-the-Art  Technology.</p>
                                </div>

                                <div className='pt-2'>
                                    <p className='text-white'>
                                    Swift & Effortless Asset Retrieval Focusing on expert retrieval solutions for both individuals and businesses, we guarantee swift and smooth asset recovery
                                    </p>
                                </div>

                                <div className='bg-[reen] h-fit flex gap-4 mr-1 mt-[15%] xl:mt-0  '>
                                   <Link to="/Contact"><button className='get__one'>Get Started</button></Link>
                                   <a href="https://haistydecrypts.com/signup/" target='_blank' rel="noreferrer">
                                        <button className='get__two'>Sign-Up Now</button>
                                   </a>
                                </div>
                            </div>
                           
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        {/* <div className="box1 overflow-hidden ">
            <section class="heroxx">
                <div class="heroxx-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
                    <div className='mx-auto overflow-hidden  md:max-w-full'>
                        <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                            <div className='bg-[reen] h-fit overflow-hidden  mt-[30%]'>
                                <div>
                                    <p className='text-white'>Your Dependable Recovery Companion</p>
                                </div>

                                <div>
                                    <p className='text-[2.1rem] xl:text-[2.5rem] font-bold text-white'>Proficient Team & Innovative, Technological Advancements</p>
                                </div>

                                <div className='pt-2'>
                                    <p className='text-white'>
                                    Efficient & Smooth Asset Retrieval Specializing in expert recovery services for 
                                    both individuals and businesses, we guarantee an expedited and effortless asset retrieval process.
                                    </p>
                                </div>

                                <div className='bg-[reen] h-fit flex gap-4 mr-1'>
                                   <Link to="/Contact"><button className='get__one'>Get Started</button></Link>
                                    <Link to="/Contact"><button className='get__two'>Report Fraud</button></Link>
                                </div>
                            </div>
                            <div className='h-fit w-fit'>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div> */}

    </Slider>


    
    </div>
  )
}

export default AppManagementReview;