import { Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Bitcoin from "./Components/Bitcoin";
import Blackmail from "./Components/Blackmail";
import AccountUnlock from "./Components/AccountUnlock";
import Romance from "./Components/Romance";
import Phone from "./Components/Phone";
import Social from "./Components/Social";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Law from "./Components/Law";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/Navbar" element={<Navbar/>}/>
      <Route path="/Bitcoin" element={<Bitcoin/>}/>
      <Route path="/Blackmail" element={<Blackmail/>}/>
      <Route path="/AccountUnlock" element={<AccountUnlock/>}/>
      <Route path="/Romance" element={<Romance/>}/>
      <Route path="/Phone" element={<Phone/>}/>
      <Route path="/Social" element={<Social/>}/>
      <Route path="/About" element={<About/>}/>
      <Route path="/Contact" element={<Contact/>}/>
      <Route path="/Law" element={<Law/>}/>
    </Routes>
  );
}

export default App;
