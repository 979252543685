import React from 'react';
import Navbar from './Navbar';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; 
import SecurityIcon from '@mui/icons-material/Security'; 
import GroupIcon from '@mui/icons-material/Group'; 
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const Bitcoin = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const fabWhiteStyle = {
    color: 'common.white',
    bgcolor: '#29d090',
    '&:hover': {
      color: '#29d090', // Change the color on hover
      bgcolor: 'transparent',
    },
  };

  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const transitionDuration = 500;

  const fabs = [
    {
      color: 'primary',
      sx: fabStyle,
      icon: <StarIcon />, // Icon for Excellence
      label: 'Excellence',
    },
    {
      color: 'secondary',
      sx: fabStyle,
      icon: <SecurityIcon />, // Icon for Integrity
      label: 'Integrity',
    },
    {
      color: 'inherit',
      sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
      icon: <GroupIcon />, // Icon for Teamwork
      label: 'Teamwork',
    },
  ];

  return (
    <>
    <Progress/>
      <Navbar />
      <div className="box1 overflow-hidden">
        <section class="Blackmail">
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#FF0102]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#FF0102]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[4rem] text-[2rem]">
                    Blackma/Extortion Recovery
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="bg-[#e5e8e8] h-fit">
        <div className="mx-auto overflow-hidden  md:max-w-7xl">
          <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
            <div className="bg-[reen] h-fit">
              <div>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}>
                 Haistydecrypts Advocates for Countering the Insidious Practices of Blackmail and Extortion. Our mission is to extend a helping hand to individuals ensnared in the distressing grip of these malicious acts. We empathize with the emotional and psychological turmoil that victims often endure when confronted with the threats and manipulation inherent in blackmail and extortion situations. Through our dedicated efforts, we aim to empower and support these individuals on their journey to recovery and justice.
                </motion.p> <br /> <br />
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.7, duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}>
               If you or someone you know is grappling with the harrowing effects of blackmail or extortion, we encourage you to take a step towards reclaiming your life. Reach out to us at Haistydecrypts, where our team of specialists is committed to providing guidance, resources, and tailored solutions to navigate these challenges. By offering a comprehensive approach, we strive to dismantle the power dynamics wielded by those who perpetrate blackmail and extortion. At  Haistydecrypts, we stand resolute in our commitment to fostering a safer environment and a brighter future for all those impacted by these harmful actions.
                </motion.p>
              </div>
            </div>
            <div className="bg-[reen] h-fit">
              <motion.div className="flex justify-center "
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                }}>
                <img src="https://static.vecteezy.com/system/resources/thumbnails/017/675/085/small_2x/unhappy-young-woman-covering-face-with-hands-crying-alone-close-up-depressed-girl-sitting-on-couch-at-home-health-problem-or-thinking-about-bad-relationships-break-up-with-boyfriend-divorce-photo.jpg" alt=""
                  className="rounded-lg img__bord" />
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <motion.div className="pt-[6%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <div className="flex justify-center">
          <p className="font-semibold text-[#FF0102]">Core Values</p>
        </div>
      </motion.div>

      <motion.div className="flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <p className="text-[46px] font-semibold text-center">Why Choose Us</p>
      </motion.div>


      <div className="mx-auto overflow-hidden  md:max-w-7xl">
            <div className="grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                <div className="bg-green-00 h-fit">
                    <div className="flex justify-center">
                        <img src="https://img.freepik.com/free-photo/positive-female-business-partners-shaking-hands_1262-20332.jpg" alt=""
                        className="rounded-md"/>
                    </div>
                </div>
                <div className="bg-green-00 h-fit">
                <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
          position: 'relative',
          minHeight: 200,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
           value={value}
           onChange={handleChange}
           indicatorColor="#FF0102" // Change this to "#29d090"
           textColor="#FF0102"
           variant="fullWidth"
           aria-label="action tabs example"
           sx={{ "& .MuiTabs-indicator": { backgroundColor: "#FF0102" } }} 
          >
            <Tab label="Expertise" {...a11yProps(0)} />
            <Tab label="Security" {...a11yProps(1)} />
            <Tab label="Trust" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <p className='font-bold'>Proficiency in Complex Retrieval</p>
          </div>
          <div className='pt-3'>
          Armed with substantial proficiency in maneuvering through intricate retrieval situations, we are poised to escort you through the intricacies of recuperating from blackmail and extortion. Rely on our seasoned team to pave the path...
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <p className='font-bold'>Comprehensive Security Offerings</p>
          </div>
          <div className='pt-3'>
          Our dedication stretches far beyond retrieval, enveloping the fortification of your future. Delve into our exhaustive security measures meticulously crafted to avert impending threats and shield your assets.
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <p className='font-bold'>Privacy and Reliance</p>
          </div>
          <div className='pt-3'>
          Reliance stands as the foundation of our rapport. Depend upon us to manage your situation with the utmost privacy and tact, ensuring your tranquility throughout the retrieval process.
          </div>
        </TabPanel>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </Box>
    </Container>
                    <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
               </div>
                </div>
            </div>

       
      </div>


      <div className="bg-[#e5e8e8] h-fit mt-5">
        <motion.div className="pt-[6%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <div className="flex justify-center">
          <p className="font-semibold text-[#FF0102]">Key Services</p>
        </div>
      </motion.div>

      <motion.div className="flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <p className="text-[46px] font-semibold">Our Services</p>
      </motion.div>




      <div className="mx-auto overflow-hidden  md:max-w-7xl">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="broken-heartxo.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Sextortion<br/>Help</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="money-back.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Money Recovery<br/>From Blackmail</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.4, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="skull.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Help From<br/>Online Threats</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.5, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="extortion.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Extortion<br/>Help</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="shield0.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">24 Hrs<br/>Protect</p>
                        </div>
                    </motion.div>
                    {/* <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.7, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="shield0.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">24 Hrs<br/>Protect</p>
                        </div>
                    </motion.div> */}
                    {/* <div className="bg-green-600 h-20">

                    </div>
                    <div className="bg-green-600 h-20">

                    </div> */}
                </div>
            </div>


            <section class="Contact__con mt-2">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-1 pb-10 pt-[5%]">
                     <div className='bggreen-600 h-fit'>
                        <div className='xl:pl-5 pl-2'>
                            <p className='font-bold xl:text-[1.8rem] text-[1.8rem]'> DO YOU WANT TO TALK TO US?</p>
                        </div>

                        <div className='xl:pl-5 pl-2'>
                            <p>
                            Have you lost something valuable or need assistance recovering lost assets? We're here to support you every step 
                            of the way. Feel free to reach out to us anytime – your concerns and inquiries matter to us, and we're dedicated 
                            to helping you regain what's rightfully yours!
                            </p>
                        </div>

                     </div>
                     <div className='bg-green-00 h-fit'>
                            <div className='xl:flex xl:justify-center flex justify-start mt-10'>
                            <button className='buttonx'>Contact Us</button>
                            </div>
                     </div>
                  </div>
               </div>
            </div>
        </section>

        </div>


        <div>
            <Footer/>
        </div>
    </>
  )
}

export default Bitcoin;
