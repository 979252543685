import React from 'react';
import Navbar from './Navbar';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";

const Bitcoin = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`action-tabpanel-${index}`}
        aria-labelledby={`action-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `action-tab-${index}`,
      'aria-controls': `action-tabpanel-${index}`,
    };
  }

  const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
  };

  const fabWhiteStyle = {
    color: 'common.white',
    bgcolor: '#29d090',
    '&:hover': {
      color: '#29d090', // Change the color on hover
      bgcolor: 'transparent',
    },
  };

  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const transitionDuration = 500;

  const fabs = [
    {
      color: 'primary',
      sx: fabStyle,
      icon: <StarIcon />, // Icon for Excellence
      label: 'Excellence',
    },
    {
      color: 'secondary',
      sx: fabStyle,
      icon: <SecurityIcon />, // Icon for Integrity
      label: 'Integrity',
    },
    {
      color: 'inherit',
      sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
      icon: <GroupIcon />, // Icon for Teamwork
      label: 'Teamwork',
    },
  ];

  return (
    <>
     <Progress />
      <Navbar />
      <div className="box1 overflow-hidden">
        <section class="Bitcoin">
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#FF0102]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#FF0102]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[4rem] text-[2rem]">
                      Crypto Recovery
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="bg-[#e5e8e8] h-fit">
        <div className="mx-auto overflow-hidden  md:max-w-7xl">
          <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
            <div className="bg-[reen] h-fit">
              <div>
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}>
                  At Haistydecrypts, we specialize in recovering all sorts of cryptocurrency assets that have been taken by scammers and fraudsters. Our Money Reclaim Asset Recovery Services is an innovative collaborative intelligence and asset tracking service dedicated to helping victims of cybercrimes and corporations recover their disappeared or stolen assets. Our team of expert professionals, led by our founder, is equipped with cutting-edge asset tracking and recovery technology to assist cybercrime victims, understand their specific circumstances, and offer them the necessary assistance for asset recovery.
                </motion.p> <br /> <br />
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.5 }}
                  transition={{ delay: 0.7, duration: 0.5 }}
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}>
                  We understand the profound impact of cybercrimes on individuals and businesses, and our commitment at Haistydecrypts is to offer a dependable and efficient service that eases the burden and distress caused by asset loss. Our dedicated team of professionals works tirelessly to ensure that every victim receives the essential support for asset recovery. Utilizing our state-of-the-art asset tracking and recovery technology, we can trace lost or stolen assets, regardless of the complexity of the situation. Our team is well-prepared to tackle any asset recovery challenge, and our dedication is to provide a service that surpasses all expectations.
                </motion.p>
              </div>
            </div>
            <div className="bg-[reen] h-fit">
              <motion.div className="flex justify-center "
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.5, duration: 0.5 }}
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                }}>
                <img src="https://media.istockphoto.com/id/1294303237/photo/bitcoin-cryptocurrency-trends-graphs-and-charts.jpg?s=612x612&w=0&k=20&c=Kh8yoLpx13JpImeGrUht9AknfY7iatsixFIPptylRn8=" alt=""
                  className="rounded-lg img__bord" />
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <motion.div className="pt-[6%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <div className="flex justify-center">
          <p className="font-semibold text-[#FF0102]">Core Values</p>
        </div>
      </motion.div>

      <motion.div className="flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <p className="text-[46px] font-semibold text-center">Why Choose Us</p>
      </motion.div>


      <div className="mx-auto overflow-hidden  md:max-w-7xl">
            <div className="grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                <div className="bg-green-00 h-fit">
                    <div className="flex justify-center">
                        <img src="https://www.marcumllp.com/wp-content/uploads/Article2-lg-960x600.jpg" alt=""
                        className="rounded-md"/>
                    </div>
                </div>
                <div className="bg-green-00 h-fit">
                <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
          position: 'relative',
          minHeight: 200,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
           value={value}
           onChange={handleChange}
           indicatorColor="#FF0102" // Change this to "#29d090"
           textColor="#FF0102"
           variant="fullWidth"
           aria-label="action tabs example"
           sx={{ "& .MuiTabs-indicator": { backgroundColor: "#FF0102" } }} 
          >
            <Tab label="Security" {...a11yProps(0)} />
            <Tab label="Feats" {...a11yProps(1)} />
            <Tab label="Approach" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <p className='font-bold'>Proficiency in Cybersecurity</p>
          </div>
          <div className='pt-3'>
          Our seasoned experts bring a wealth of expertise to the realm of cybersecurity, digital forensics, and financial tracing. We harness state-of-the-art methodologies to trace fraudulent hackers and facilitate the retrieval of your assets.
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <p className='font-bold'>History of Achievements</p>
          </div>
          <div className='pt-3'>
          Over the years, we've consistently aided a multitude of clients in recuperating their lost assets from counterfeit hackers. Our established history of achievements underscores our dedication and effectiveness in this domain.
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <p className='font-bold'>Client-Focused Approach</p>
          </div>
          <div className='pt-3'>
          Your contentment is our paramount concern. We prioritize your requirements and endeavor to furnish a seamless and trouble-free journey from the initial consultation to the successful restoration of your assets.
          </div>
        </TabPanel>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </Box>
    </Container>
                    <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Expertise</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
               </div>
                </div>
            </div>

       
      </div>


      <div className="bg-[#e5e8e8] h-fit mt-5">
        <motion.div className="pt-[6%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <div className="flex justify-center">
          <p className="font-semibold text-[#FF0102]">Key Services</p>
        </div>
      </motion.div>

      <motion.div className="flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <p className="text-[46px] font-semibold">Our Services</p>
      </motion.div>




      <div className="mx-auto overflow-hidden  md:max-w-7xl">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 sm:grid-cols-2 md:grid-cols-2 gap-5 pb-10 pt-[5%]">
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.2, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="trendx.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">All Cryptocurrencies <br/> Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.3, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="mobile-banking.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Fake Bank <br/>Investment Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.4, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="investment.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Fake Crypto<br/>Investment Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.5, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="fake-news.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Fake Broker site<br/>Investment Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.6, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="crypto-wallet.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">Hacked Crypto <br/> Wallet Recovery</p>
                        </div>
                    </motion.div>
                    <motion.div className="bg-green-00 h-fit" 
                     initial="hidden"
                     whileInView="visible"
                     viewport={{ once: true, amount: 0.5 }}
                     transition={{ delay: 0.7, duration: 0.5 }}              
                     variants={{
                     hidden: { opacity: 0, y: 50 },
                     visible: { opacity: 1, y: 0 },
                     }}>
                        <div className="flex justify-center">
                            <img src="shield0.png" alt="" className="w-[64px]"/>
                        </div>

                        <div className="flex justify-center">
                            <p className="text-center font-semibold pt-3">24 Hrs<br/>Protect</p>
                        </div>
                    </motion.div>
                    {/* <div className="bg-green-600 h-20">

                    </div>
                    <div className="bg-green-600 h-20">

                    </div> */}
                </div>
            </div>


            <section class="Contact__con mt-2">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-1 pb-10 pt-[5%]">
                     <div className='bggreen-600 h-fit'>
                        <div className='xl:pl-5 pl-2'>
                            <p className='font-bold xl:text-[1.8rem] text-[1.8rem]'> DO YOU WANT TO TALK TO US?</p>
                        </div>

                        <div className='xl:pl-5 pl-2'>
                            <p>
                            Have you lost something valuable or need assistance recovering lost assets? We're here to support you every step 
                            of the way. Feel free to reach out to us anytime – your concerns and inquiries matter to us, and we're dedicated 
                            to helping you regain what's rightfully yours!
                            </p>
                        </div>

                     </div>
                     <div className='bg-green-00 h-fit'>
                            <div className='xl:flex xl:justify-center flex justify-start mt-10'>
                            <button className='buttonx'>Contact Us</button>
                            </div>
                     </div>
                  </div>
               </div>
            </div>
        </section>

        </div>


        <div>
            <Footer/>
        </div>
    </>
  )
}

export default Bitcoin;
