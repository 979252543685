import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import StarIcon from '@mui/icons-material/Star';


const Review = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  
    const customColorStyle = {
      color: '#FF0102',
    };

  return (
    <div className="carouselx h-fit">
       

      <Slider {...settings} className='dragging mt-10' >

      <div className='slide-wrapper '>
           <div className="bg-[white] h-full overflow-hidden  ml-2 mr-2 review__bord">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/1200x/80/c5/e3/80c5e3ed76b79880b743c581be69e2d2.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3">John Drill</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0'>
                    I found myself ensnared in the painful web of a romance scam that exacted a devastating toll, amounting to $80,000 
                    drained from my finances. The experience was nothing short of heart-wrenching, leaving me in a state of profound 
                    despair and anguish. Despite numerous valiant attempts to reclaim my lost funds, I found myself at an impasse, 
                    teetering on the brink of hopelessness.
                    It was during this darkest hour that fate intervened, and I had the remarkable fortune of crossing paths with 
                    Haistydecrypts. With unwavering determination and unparalleled expertise, Haistydecrypts emerged as a beacon of light in my life.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0]'> Massachusetts • USA • 6 days Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       

        <div className='slide-wrapper '>
            <div className="bg-[white] !h-full overflow-hidden  ml-2 mr-2 review__bord">
                <div className="bg-[reen] !h-full  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/736x/05/0a/51/050a511d3d5a5ba0d66aec2a8e7e9ad0.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3"> Emily Rodriguez</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0'>
                    Over the course of several years, I unknowingly poured my life and over $670,000 into what I thought was a legitimate 
                    investment platform, only to discover that it was an elaborate scam. My world seemed to crumble as I grappled with the 
                    reality of the situation. However, a glimmer of hope emerged when I stumbled upon this dedicated team that specialized in 
                    recovering lost investments. Their professionalism and expertise were a beacon of light in my darkest days, and I was 
                    immensely relieved when, against all odds, they successfully recovered my hard-earned money.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0]'> Tennessee • USA • 1 week Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
        <div className='slide-wrapper '>
            <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://img.buzzfeed.com/buzzfeed-static/static/2016-04/22/11/enhanced/webdr08/enhanced-25647-1461340783-1.png?output-quality=auto&output-format=auto&downsize=640:*' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3">Tracy McGee</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0'>
                    I found myself ensnared in a harrowing blackmail attempt that left me in a state of profound distress and anxiety. 
                    It was an ordeal that threatened to shatter my reputation and personal life. Fortunately, I had the good fortune 
                    to cross paths with Haistydecrypts, a true expert in handling such dire situations. Haistydecrypts unwavering 
                    professionalism and strategic acumen were nothing short of remarkable. They not only protected my reputation but 
                    also swiftly put an end to the extortion that had plagued me. It was a testament to thier expertise, and I couldn't 
                    be more grateful.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0]'> Philadelphia • USA • 3 weeks Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>


        <div className='slide-wrapper '>
            <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.redd.it/o7ch45rcg9311.jpg' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3">Kelvin Dyce</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0'>
                    I faced a dire situation when I encountered a locked device that appeared beyond any hope of repair. This device held invaluable 
                    data that I feared might be lost forever, and the anxiety was overwhelming. However, my faith in a solution was restored when I 
                    enlisted the expertise of  Haistydecrypts. This team's skill and determination proved to be nothing short of miraculous. They successfully 
                    unlocked my device and meticulously restored all the precious information it contained. My gratitude knows no bounds.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0]'> Mississauga • Canada • 1 day Ago</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
        <div className='slide-wrapper '>
            <div className="bg-[white] h-fit overflow-hidden  ml-2 mr-2 review__bord">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        >
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://pbs.twimg.com/media/E9Cp-cBVkAEj458.jpg:large' alt=""/>
                            </Avatar>
                        </StyledBadge>
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3">Benjamin Carter</p>
                    </div>
                    <div className='!flex !justify-center'>
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                        <StarIcon style={customColorStyle} fontSize="small" />
                    </div>

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0'>
                    The invasion of my social media accounts left me in a state of vulnerability and despair. My personal information had been 
                    compromised, and I felt utterly helpless. It was a nightmare I couldn't escape until  came to my rescue. 
                    Haistydecrypts acted swiftly, demonstrating exceptional competence in restoring my accounts and ensuring my online security. 
                    Their professionalism and dedication were truly commendable. Thanks to Them, I regained control of my digital life, and I am 
                    profoundly thankful for thier expertise.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0]'>  Brighton • United Kingdom • 1 Month</p>
                    </div>
                </div>

                </div>   
           </div>
        </div>
       
      
      </Slider>

      <div className='text-center pt-10'>
        <p>swipe to see more.</p>
      </div>
    </div>
  );
}

export default Review;