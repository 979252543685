import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // If you are using React Router
import { FiMenu } from 'react-icons/fi'; // Import icons you need
import { Avatar } from '@mui/material';
import {  FaInstagram, FaEnvelope } from 'react-icons/fa';
import { BiLogoTelegram } from "react-icons/bi";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed top-0 left-0 w-full z-40 transition-all duration-300 ${
        scrolling ? 'hidden' : 'bg-transparent shadow-md'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <img src="hx.png" alt="" className="w-[100px] xl:w-[100px]" />
            </Link>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <Link to="/" className="text-[white]">
              Home
            </Link>
            <a href="#Review" className="text-white">
              Reviews
            </a>
            <div className="relative inline-block text-left items-center justify-center">
              <div className="flex">
                <button onClick={toggleServices} className="text-[white]">
                  Services
                </button>{' '}
                <i
                  className="ri-arrow-down-s-line text-[black] cursor-pointer"
                  onClick={toggleServices}
                ></i>
              </div>
              {isServicesOpen && (
                <div className="absolute z-10 mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-[15rem]">
                  <div className="flex justify-start ml-2">
                    <i
                      className="ri-close-line text-[black] pb- cursor-pointer text-[1.3rem]"
                      onClick={toggleServices}
                    ></i>
                  </div>
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu "
                  >
                    <a
                      href="/Blackmail"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Blackmail/Extortion
                    </a>
                    <a
                      href="/Bitcoin"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Crypto Recovery
                    </a>
                    <a
                      href="/AccountUnlock"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Account Unlock
                    </a>
                    <a
                      href="/Romance"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Romance Scam Recovery
                    </a>
                    <a
                      href="/Social"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Social Media Hack/Recovery
                    </a>
                    <a
                      href="/Phone"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      role="menuitem"
                    >
                      Phone/Device Unlock
                    </a>
                  </div>
                </div>
              )}
            </div>
            <a href="/About" className="text-white">
              About
            </a>
            <a href="/Contact" className="text-white">
              Contact
            </a>
            <a href="#FAQ" className="text-white">
              F.A.Q
            </a>
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleSidebar}
              className="text-[white] hover:text-gray-900 focus:outline-none"
            >
              <FiMenu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
      {/* Dark overlay when sidebar is toggled */}
      {isOpen && (
        <div
          onClick={toggleSidebar}
          className="fixed inset-0 bg-black opacity-50 z-40"
        ></div>
      )}
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 bg-white w-64 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-50`}
      >
        {/* Sidebar content */}
        <div className="flex flex-col h-full p-4 space-y-4 bg-[#fafafa]">
          <div className="flex items-center justify-between">
            <Link to="/" className="text-xl font-bold">
              <img src="fxzo.png" alt="" className="w-[40%]" />
            </Link>
            <button onClick={toggleSidebar} className="text-gray-600">
              <i className="ri-close-line text-[#FF0102] pb- cursor-pointer text-[1.3rem]"></i>
            </button>
          </div>

          <div>
            <Link to="/" className="text-[black]">
              Home
            </Link>
          </div>
          <div className="relative inline-block text-left items-center justify-center">
            <div className="flex">
              <button onClick={toggleServices} className="text-[black]">
                Services
              </button>{' '}
              <i
                className="ri-arrow-down-s-line text-[black] cursor-pointer"
                onClick={toggleServices}
              ></i>
            </div>
            {isServicesOpen && (
              <div className="absolute z-10 mt-2 bg-white border border-gray-200 rounded-md shadow-lg w-[14rem]">
                <div className="flex justify-start ml-2">
                  <i
                    className="ri-close-line text-black pb- cursor-pointer text-[1.3rem]"
                    onClick={toggleServices}
                  ></i>
                </div>
                <div
                  className="py-1"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu "
                >
                  <a
                    href="/Blackmail"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Blackmail/Extortion
                  </a>
                  <a
                    href="/Bitcoin"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Crypto Recovery
                  </a>
                  <a
                    href="/AccountUnlock"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Account Unlock
                  </a>
                  <a
                    href="/Romance"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Romance Scam Recovery
                  </a>
                  <a
                    href="/Social"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Social Media Hack/Recovery
                  </a>
                  <a
                    href="/Phone"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                  >
                    Phone/Device Unlock
                  </a>
                </div>
              </div>
            )}
          </div>

          <a href="/About" className="text-[black]">
            About
          </a>
          <a href="/Contact" className="text-[black]">
            Contact Us
          </a>
          <a href="#Review" className="text-[black]">
            Review
          </a>
          <a href="#FAQ" className="text-[black]">
            F.A.Q
          </a>
          <a href="https://haistydecrypts.com/signup/" target='_blank' rel="noreferrer">
           Sign-up
          </a>
          <a href="https://haistydecrypts.com/signup/login" target='_blank' rel="noreferrer">
            Login
          </a>
          <div className="pt-5 flex gap-5">
           
               <a href="mailto:Haistydecrypts@gmail.com">
              <Avatar className="!bg-[#FF0102]">
                <FaEnvelope size={21} />
            </Avatar>
            </a>

          
            
            <Avatar className="!bg-[#FF0102]">
              <a href="https://t.me/haistydecrypts"  target='_blank' rel="noreferrer">
              <BiLogoTelegram size={21} />
              </a>
            </Avatar>
            <Avatar className="!bg-[#FF0102]">
              <a href="https://www.instagram.com/haistydecrypts?igsh=OGQ5ZDc2ODk2ZA==" target='_blank' rel="noreferrer">
                <FaInstagram size={23} />
              </a>
            </Avatar>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
