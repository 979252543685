import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; // Icon for Excellence
import SecurityIcon from '@mui/icons-material/Security'; // Icon for Integrity
import GroupIcon from '@mui/icons-material/Group'; // Icon for Teamwork
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const fabWhiteStyle = {
    color: 'common.white',
    bgcolor: '#29d090',
    '&:hover': {
      color: '#29d090', // Change the color on hover
      bgcolor: 'transparent',
    },
  };

export default function FloatingActionButtonZoom() {
  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const transitionDuration = 500;

  const fabs = [
    {
      color: 'primary',
      sx: fabStyle,
      icon: <StarIcon />, // Icon for Excellence
      label: 'Excellence',
    },
    {
      color: 'secondary',
      sx: fabStyle,
      icon: <SecurityIcon />, // Icon for Integrity
      label: 'Integrity',
    },
    {
      color: 'inherit',
      sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
      icon: <GroupIcon />, // Icon for Teamwork
      label: 'Teamwork',
    },
  ];

  return (
    <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
          position: 'relative',
          minHeight: 200,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
           value={value}
           onChange={handleChange}
           indicatorColor="#29d090y" // Change this to "#29d090"
           textColor="#29d090"
           variant="fullWidth"
           aria-label="action tabs example"
           sx={{ "& .MuiTabs-indicator": { backgroundColor: "#FF0102" } }} 
          >
            <Tab label="Excellence" {...a11yProps(0)} />
            <Tab label="Integrity" {...a11yProps(1)} />
            <Tab label="Teamwork" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <p className='font-bold'>Continuous Improvement</p>
          </div>
          <div className='pt-3'>
            Through continuous refinement, we work diligently and tirelessly to surpass your expectations. Our unwavering commitment ensures that we won't rest until we've achieved excellence and completed the job to your utmost satisfaction.
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <p className='font-bold'>Reliable Integrity</p>
          </div>
          <div className='pt-3'>
            Unwavering Ethical Commitment: We uphold the highest standards of trustworthiness and ethical conduct. Our reliable integrity is the cornerstone of our service, ensuring your complete confidence in our work. We are dedicated to maintaining your trust, always, because your peace of mind matters.
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <p className='font-bold'>Resilient Teamwork</p>
          </div>
          <div className='pt-3'>
            Collaborative Determination: Our resilient teamwork thrives on collaboration and determination. We unite as a dedicated team, facing challenges head-on and delivering results together. With unwavering determination, we work seamlessly to achieve your goals, every step of the way.
          </div>
        </TabPanel>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </Box>
    </Container>
  );
}
