import React from 'react';
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { useRef, useEffect, } from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import StarIcon from '@mui/icons-material/Star'; 
import SecurityIcon from '@mui/icons-material/Security'; 
import GroupIcon from '@mui/icons-material/Group'; 
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circularx from "./hooks/Circularx";
import Circular from "./hooks/Circular";
import Circularxx from "./hooks/Circularxx";
import Review from "./hooks/Review";
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";


const About = () => {
  const AnimatedNumbers = ({ value }) => {
    const ref = useRef(null);
    const motionValue = useMotionValue(0);
    const springValue = useSpring(motionValue, { duration: 6000 });
    const isInview = useInView(ref);

    useEffect(() => {
        if (isInview) {
            motionValue.set(value);
        }
    }, [isInview, value, motionValue]);

    useEffect(() => {
        springValue.on("change", (latest) => {
            if (ref.current && latest.toFixed(0) <= value) {
                ref.current.textContent = parseInt(latest).toLocaleString(); 
            }
        });
    }, [springValue, value]);

    return <span ref={ref}></span>;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const fabWhiteStyle = {
  color: 'common.white',
  bgcolor: '#',
  '&:hover': {
    color: '#FF0102', // Change the color on hover
    bgcolor: 'transparent',
  },
};

const [value, setValue] = React.useState(0);
const isMobile = useMediaQuery('(max-width: 600px)');

const handleChange = (event, newValue) => {
  setValue(newValue);
};

const transitionDuration = 500;

const fabs = [
  {
    color: 'primary',
    sx: fabStyle,
    icon: <StarIcon />, // Icon for Excellence
    label: 'Excellence',
  },
  {
    color: 'secondary',
    sx: fabStyle,
    icon: <SecurityIcon />, // Icon for Integrity
    label: 'Integrity',
  },
  {
    color: 'inherit',
    sx: { ...fabStyle, ...fabWhiteStyle }, // Custom style for Teamwork
    icon: <GroupIcon />, // Icon for Teamwork
    label: 'Teamwork',
  },
];

  return (
    <>
    <Progress/>
    <Navbar/>
    <div className="box1 overflow-hidden">
        <section class="About">
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#FF0102]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#FF0102]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[4rem] text-[2rem]">
                        About Us
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="bg-[#e5e8e8] h-fit">
        <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className="grid ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 mt-[5%]">
                        <div className="bg-[reen] h-fit">
                            <div>
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.5, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}> 
                                    At Haistydecrypts, we stand firmly by your side, poised to thwart the maneuvers of 
                                    fraudsters and resolutely restore the fruits of your hard-earned labor. Through the fusion of our 
                                    specialized expertise and cutting-edge technological prowess, we offer you an unparalleled mosaic of 
                                    tranquility by reclaiming financial assets ensnared within the clutches of fraudulent schemes. Our team 
                                    of seasoned professionals comprehends the profound repercussions of falling prey to scams, thus 
                                    addressing each case with the finesse of a master craftsman. Meticulous in our discretion, we tailor 
                                    bespoke solutions finely tuned to the unique cadence of your circumstances. Whether ensnared in the 
                                    maze of online phishing, victimized by investment deceit, or ensnared by the allure of romance turned 
                                    treacherous, Haistydecrypts stand ready to unravel even the most intricate of 
                                    predicaments.
                                </motion.p> <br/> 
                                <motion.p
                                 initial="hidden"
                                 whileInView="visible"
                                 viewport={{ once: true, amount: 0.5 }}
                                 transition={{ delay: 0.7, duration: 0.5 }}              
                                 variants={{
                                 hidden: { opacity: 0, y: 50 },
                                 visible: { opacity: 1, y: 0 },
                                 }}>
                               With a history of triumphant retrievals and an expansive network of esteemed clients worldwide, we have 
                               cemented our reputation as an impregnable fortress of recovery. As we diligently toil to restore your 
                               assets, we simultaneously dispatch an unambiguous message to those who perpetrate deception: their 
                               schemes will meet swift and exacting retribution. Bestow your unwavering confidence in Haistydecrypts
                                to redefine the landscape of romance scam recovery and illuminate the pathway 
                               to financial renewal.
                                </motion.p>
                            </div>
                        </div>
                        <div className="bg-[reen] h-fit">
                            <motion.div className="flex justify-center "
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.5, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <img src="https://img.freepik.com/premium-photo/side-view-portrait-female-it-developer-using-computer-while-coding-modern-white-office-interior-copy-space_236854-23779.jpg" alt=""
                                className="rounded-lg img__bord"/>
                            </motion.div>
                        </div>
                    </div>
        </div>

        
        <div className=" mx-auto overflow-hidden md:max-w-6xl">
                     <div className="grid  grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-0 pb-10 mt-[2%]">
                            <div className="bg-[reen] h-fit flex justify-center overflow-hidden">
                            <div className=''>
                                <p className='text-[1.5rem] xl:text-[2rem] font-bold text-center'>
                                    <AnimatedNumbers value={12000}/><span className="text-[#FF0102]">+</span>
                                </p>
                                <p className='text-center text-[1.2rem] font-bold text-[#FF0102]'>
                                   Trusted <br/> Clients
                                </p>
                          </div>
                            </div>

                            <div className="bg-[reen] h-fit flex justify-center overflow-hidden">
                            <div>
                                <p className='text-[1.5rem] xl:text-[2rem] font-bold text-center'>
                                    $<AnimatedNumbers value={50}/><span className='text-[#FF0102]'>m+</span>
                                </p>
                                <p className='text-center text-[1.2rem] font-bold text-[#FF0102]'>
                                    Money <br/> Returned
                                </p>
                          </div>
                            </div>
                            <div className="bg-[reen] h-fit flex justify-center overflow-hidden">
                            <div>
                                <p className='text-[1.5rem] xl:text-[2rem] font-bold text-center'>
                                    <AnimatedNumbers value={11}/><span className="text-[#FF0102]">+</span>
                                </p>
                                <p className='text-center text-[1.2rem] font-bold text-[#FF0102]'>
                                    Years Of <br/> Experience
                                </p>
                          </div>
                            </div>
                     </div>
                </div>
                </div>

                <motion.div className="pt-[6%]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <div className="flex justify-center">
          <p className="font-semibold text-[#FF0102]">Core Values</p>
        </div>
      </motion.div>

      <motion.div className="flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        }}>
        <p className="text-[46px] font-semibold text-center">Why Choose Us</p>
      </motion.div>


      <div className="mx-auto overflow-hidden  md:max-w-7xl">
            <div className="grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                <div className="bg-green-00 h-fit">
                    <div className="flex justify-center">
                        <img src="https://img.freepik.com/free-photo/positive-female-business-partners-shaking-hands_1262-20332.jpg" alt=""
                        className="rounded-md"/>
                    </div>
                </div>
                <div className="bg-green-00 h-fit">
                <Container maxWidth={isMobile ? 'md' : 'xl'} disableGutters={true}>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: 'background.paper',
          width: '100%',
          position: 'relative',
          minHeight: 200,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <AppBar position="static" color="default">
          <Tabs
           value={value}
           onChange={handleChange}
           indicatorColor="#FF0102" // Change this to "#29d090"
           textColor="#FF0102"
           variant="fullWidth"
           aria-label="action tabs example"
           sx={{ "& .MuiTabs-indicator": { backgroundColor: "#FF0102" } }} 
          >
            <Tab label="Prosperity" {...a11yProps(0)} />
            <Tab label="Trust" {...a11yProps(1)} />
            <Tab label="Relationships" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div>
            <p className='font-bold'>Enduring Prosperity</p>
          </div>
          <div className='pt-3'>
          Entities and individuals grounded in a foundation of authenticity often enjoy enduring prosperity. Long-term success frequently emerges from ethical conduct and unwavering sincerity.
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <p className='font-bold'>Foundation of Trust</p>
          </div>
          <div className='pt-3'>
          Trust is built upon the bedrock of authenticity. Individuals and organizations that consistently embody honesty and transparency in their actions are more likely to garner the confidence of others.
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div>
            <p className='font-bold'>Strong Relationships</p>
          </div>
          <div className='pt-3'>
          Authenticity fosters strong and meaningful relationships. People are naturally drawn to those who exhibit sincerity, as it engenders a sense of genuineness and authentic engagement.
          </div>
        </TabPanel>
        {fabs.map((fab, index) => (
          <Zoom
            key={fab.color}
            in={value === index}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${value === index ? transitionDuration : 0}ms`,
            }}
            unmountOnExit
          >
            <Fab sx={fab.sx} aria-label={fab.label} color={fab.color}>
              {fab.icon}
            </Fab>
          </Zoom>
        ))}
      </Box>
    </Container>
                    <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
               </div>
                </div>
            </div>

       
      </div>

      <div className="bg-[#e5e8e8] h-fit">
      <motion.div className="pt-[6%]"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#FF0102] uppercase">TOP Testimonies</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold text-center">Reviews</p>
                </motion.div>
        <div className="mx-auto overflow-hidden  md:max-w-7xl">
              <Review/>
        </div>


        <section class="Contact__con mt-10">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-1 pb-10 pt-[5%]">
                     <div className='bggreen-600 h-fit'>
                        <div className='xl:pl-5 pl-2'>
                            <p className='font-bold xl:text-[1.8rem] text-[1.8rem]'> DO YOU WANT TO TALK TO US?</p>
                        </div>

                        <div className='xl:pl-5 pl-2'>
                            <p>
                            Have you lost something valuable or need assistance recovering lost assets? We're here to support you every step 
                            of the way. Feel free to reach out to us anytime – your concerns and inquiries matter to us, and we're dedicated 
                            to helping you regain what's rightfully yours!
                            </p>
                        </div>

                     </div>
                     <div className='bg-green-00 h-fit'>
                            <div className='xl:flex xl:justify-center flex justify-start mt-10'>
                            <button className='buttonx'>Contact Us</button>
                            </div>
                     </div>
                  </div>
               </div>
            </div>
        </section>
    

        <Footer/>
      </div>

      
    </>
  )
}

export default About