import React from 'react';
import Navbar from "./Navbar";
import Landing from './hooks/Landing';
import { motion } from "framer-motion";
import { useRef, useEffect } from 'react';
import { useInView, useMotionValue, useSpring } from 'framer-motion';
import { Avatar } from '@mui/material';
// import { Link } from "react-router-dom";
import Taps from "./hooks/Taps";
import Circular from "./hooks/Circular";
import Circularx from "./hooks/Circularx";
import Circularxx from "./hooks/Circularxx";
import Review from "./hooks/Review";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import Footer from "./hooks/Footer";
import Progress from "./hooks/Progress";

const Home = () => {
    const AnimatedNumbers = ({ value }) => {
        const ref = useRef(null);
        const motionValue = useMotionValue(0);
        const springValue = useSpring(motionValue, { duration: 6000 });
        const isInview = useInView(ref);
    
        useEffect(() => {
            if (isInview) {
                motionValue.set(value);
            }
        }, [isInview, value, motionValue]);
    
        useEffect(() => {
            springValue.on("change", (latest) => {
                if (ref.current && latest.toFixed(0) <= value) {
                    ref.current.textContent = parseInt(latest).toLocaleString(); // Add this line to format the value with commas
                }
            });
        }, [springValue, value]);
    
        return <span ref={ref}></span>;
    };
  return (
    <>
    <Progress/>
    <div className='mx-auto overflow-hidden  md:max-w-full'>
            <Navbar/>
            <Landing/>
    </div>


        <div className='bg-[#f7f7f8] h-fit'>
            <div className="mx-auto overflow-hidden  md:max-w-7xl ">
                <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                    <div className="bg-[reen] h-fit hidden md:block xl:block">
                        <div className="flex justify-center">
                            <img src="https://www.santander.com/content/dam/santander-com/es/stories/cabecera/2022/-ciberseguridad/im-storie-asi-ayuda-cyber-guardian-a-las-pymes-a-protegerse-en-el-mundo-digital-movil.jpg.transform/rendition-sm/image.jpg" 
                            alt="" className="h-[99vh]"/>
                        </div>
                    </div>
                    <div className="bg-[reen] h-fit">
                        <div className="bg-[ink] h-fit">
                            <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.2, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <p className="font-semibold text-[1.2rem] text-[#FF0102]">Our Ploicy</p>
                            </motion.div> 

                            <motion.div
                            initial="hidden"
                            whileInView="visible"
                            viewport={{ once: true, amount: 0.5 }}
                            transition={{ delay: 0.4, duration: 0.5 }}              
                            variants={{
                            hidden: { opacity: 0, y: 50 },
                            visible: { opacity: 1, y: 0 },
                            }}>
                                <p className="font-bold xl:text-[46px] text-[36px] ">
                                    <motion.span
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.6, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>Advantages of </motion.span>  <br/> 
                                    <motion.span
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.5 }}
                                    transition={{ delay: 0.8, duration: 0.5 }}              
                                    variants={{
                                    hidden: { opacity: 0, y: 50 },
                                    visible: { opacity: 1, y: 0 },
                                    }}>Collaborating with Us</motion.span> 
                                </p>
                            </motion.div>       
                        </div>
                        <motion.div className="bg-[white] h-fit mt-5 pl-5 pr-5 pt-3 pb-3 rounded-md"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.4 }}
                        transition={{ delay: 0.4, duration: 0.4 }}
                        variants={{
                            hidden: { opacity: 0, x: 50 },
                            visible: { opacity: 1, x: 0 },
                        }}>
                            <div className="flex">
                                <div>
                                    <img src="refund.png" alt="" className="w-[64px]"/>
                                </div>
                                <div className="pl-5 pt-6">
                                    <p className="font-bold">
                                        Refund policy
                                    </p>
                                </div>
                            </div>

                            <div className="pt-1">
                            Harnessing a broad spectrum of expertise at our disposal, we maintain robust confidence in our remarkable 
                            success rate, minimizing the likelihood of refund requests. Nevertheless, should you find our services 
                            unsatisfactory, be assured that we provide a rapid and uncomplicated refund procedure.
                            </div>
                        </motion.div>
                        <motion.div className="bg-[white] h-fit mt-5 pl-5 pr-5 pt-3 pb-3 rounded-md"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.4 }}
                        transition={{ delay: 0.6, duration: 0.4 }}
                        variants={{
                            hidden: { opacity: 0, x: 50 },
                            visible: { opacity: 1, x: 0 },
                        }}>
                            <div className="flex">
                                <div>
                                    <img src="effort.png" alt="" className="w-[64px]"/>
                                </div>
                                <div className="pl-5 pt-6">
                                    <p className="font-bold">
                                    Resilience
                                    </p>
                                </div>
                            </div>

                            <div className="pt-1">
                            Inside our recovery agency, we embody the essential characteristic of resilience. Our 
                            commitment to assisting clients in challenging situations remains unwavering. With a wide 
                            range of skills, we maintain an outstanding success rate, lessening the need for reimbursements.
                            </div>
                        </motion.div>
                        <motion.div className="bg-[white] h-fit mt-5 pl-5 pr-5 pt-3 pb-3 rounded-md"
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.4 }}
                        transition={{ delay: 0.8, duration: 0.4 }}
                        variants={{
                            hidden: { opacity: 0, x: 50 },
                            visible: { opacity: 1, x: 0 },
                        }}>
                            <div className="flex">
                                <div>
                                    <img src="target0.png" alt="" className="w-[64px]"/>
                                </div>
                                <div className="pl-5 pt-6">
                                    <p className="font-bold">
                                    Accuracy
                                    </p>
                                </div>
                            </div>

                            <div className="pt-1">
                            Discover the sheer brilliance of our highly flexible and constantly evolving team, brimming with expertise across 
                            a multitude of domains. Witness the seamless unity as we come together to expertly coordinate and attain flawless 
                            outcomes, guaranteeing nothing falls below the standard of excellence.impact
                            </div>
                        </motion.div>

                    </div>
                </div>
            </div>
        </div>

        <div>
        <section class="numbers">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]">
                      <motion.div className='bg-[reen] h-fit'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.2, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <div className='flex'>
                          <div className="bg-[#FF0102] pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="money.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                                $<AnimatedNumbers value={50}/>m+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Money Restored
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.4, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <div className='flex'>
                          <div className="bg-[#FF0102]  pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="client.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={10000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Satisfied Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.6, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <div className='flex'>
                          <div className="bg-[#FF0102]  pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="trusto.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={12000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Trusted 
                            </p>
                          </div>
                        </div>
                      </motion.div>
                      <motion.div className='bg-[reen] h-fit'
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ delay: 0.8, duration: 0.5 }}              
                        variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                        }}>
                        <div className='flex'>
                          <div className="bg-[#FF0102]  pl-[10px] pr-[10px] pt-[10px] pb-[10px] rounded-lg">
                            <img src="personal-data.png" alt="" className='w-[64px]'/>
                          </div>

                          <div>
                            <p className='text-[2rem] font-bold pl-2'>
                            <AnimatedNumbers value={8000}/>+
                            </p>
                            <p className='pl-2 text-[1.2rem] font-bold'>
                              Protected Clients
                            </p>
                          </div>
                        </div>
                      </motion.div>
                  </div>
               </div>
            </div>
        </section>

        <div className='bg-[#f7f7f8] h-fit'>
        <motion.div className="pt-[6%]"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#FF0102]">TOP SOLUTIONS</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold">Our Services</p>
                </motion.div>


            
           
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
            <div className='grid mt-10 xl:mt-0 ml-1 mr-1 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-3 pb-10 pt-[5%]'>
                <motion.div className='bg-reen-600 h-full serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.2, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#FF0102]">
                                <img src="bitcoin-logo.png" alt="" className="!w-[80px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                    Crypto Currency Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Have you ever experienced the annoyance of losing funds or cryptocurrency slipping away from your control? Fear 
                            not, as we're here to steer you through the intricate process of their retrieval. Be confident that, with our 
                            proficiency, you can reclaim access to your inaccessible wallet. Your path becomes more seamless with the 
                            skillful support provided by Haistydecrypts and their committed team of specialists.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Bitcoin" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-full serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.4, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[66px] !h-[66px] border !border-[#FF0102]">
                                <img src="uncovered.png" alt="" className="!w-[80px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Blackmail / Extortion Help
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Empowering and Advocating for Victims of Online Blackmail, Sextortion, and Threats. Our dedicated mission is to provide unwavering 
                            support and compassionate assistance to individuals who have unfortunately encountered these distressing experiences. We steadfastly 
                            stand by their side, offering expert guidance to those who have fallen prey to online threats and malicious intent.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Blackmail" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.5, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#FF0102]">
                                <img src="unlocked.png" alt="" className="!w-[124px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Account Unlock / Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Unlock the potential to regain access to your locked accounts with our tailored tools. Experience the convenience 
                            of our solutions designed to overcome account lockouts effortlessly. At Haistydecrypts, we specialize in swift 
                            account recovery, providing you with the means to regain control and peace of mind for your digital assets. 
                            Trust our experts to navigate the path to account restoration with expertise.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/AccountUnlock" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-fit serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.6, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#FF0102]">
                                <img src="broken-heart.png" alt="" className="!w-[80px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Romance Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Assisting Those Affected by Romance Scams, we're committed to helping you overcome the distress caused by these deceitful actions. With our focused experts, we're determined to rebuild your confidence and guide you through the recovery of your lost assets. Trust us to lead you towards recovery with unwavering commitment. Let our dedicated team assist you in overcoming distress and expertly navigate the path to recovering your lost assets.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Romance" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-full serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.7, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#FF0102]">
                                <img src="password.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Phone Hack/unlock
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Haistydecrypts, Comprising a team of esteemed specialists in phone unlocking, rest assured, no detail 
                            escapes our scrutiny. Our meticulous expertise and unwavering commitment guarantee thoroughness in each
                             phase, delivering definitive solutions tailored to your requirements. Place your trust in us for an 
                             unparalleled, hassle-free experience. Our mission is to safeguard your digital assets with dedication 
                             and precision.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5">
                            <a href="/Phone" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
                <motion.div className='bg-reen-600 h-full serv__box' 
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.8, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                
                        <div className="pl-6 pt-3 flex">
                            <Avatar className="!bg-[white] !w-[60px] !h-[60px] border !border-[#FF0102]">
                                <img src="social-media.png" alt="" className="!w-[64px] pl-3 pr-3 pt-3 pb-3"/>
                            </Avatar>

                            <div className="pl-4 pt-4">
                                <p className="font-semibold ">
                                Social Media Hack / Recovery
                                </p>
                            </div>
                        </div>

                        <div className="pl-5 pr-2  pb-3 pt-3">
                            <p>
                            Empowering Your Digital Connections: Social accounts act as crucial links to numerous achievements and cherished 
                            connections. Recognizing their importance, we stand unwavering in our dedication to surpass expectations, leaving 
                            no stone unturned in ensuring your swift account recovery. Trust in our unwavering expertise and commitment to 
                            promptly reinstate your access.
                            </p>
                        </div>

                        <div className="flex pb-5 ml-5 mt-5">
                            <a href="/Social" className="">
                                <button className="learn__x">Learn More</button>
                            </a>
                        </div>
                </motion.div>
            </div>
        </div>
            </div>



            <motion.div className="pt-[6%]"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#FF0102]">WHY CHOOSE US</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold text-center">Our Core Values</p>
                </motion.div>


                <div className="mx-auto overflow-hidden  md:max-w-7xl">
                    <div className='grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-green-600 h-fit'>
                            <div>
                                <img src="https://s3-us-east-2.amazonaws.com/maryville/wp-content/uploads/2017/05/25123845/Women_cybersecurity_750x500.jpg"
                                alt=""/>
                            </div>
                        </div>
                        <div className='bg-green-00 h-fit'>
                            <Taps/>


                            <div className='bg-[ink] h-fit flex mt-8'>
                            <div className='bg-[range] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circular/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Trust</p>
                                </div>
                            </div>
                            <div className='bg-[urple] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Security</p>
                                </div>
                            </div>
                            <div className='bg-[ray] h-fit w-full'>
                                <div className='flex justify-center'>
                                    <Circularxx/>
                                </div>
                                <div className='flex justify-center'>
                                    <p className='text-center font-bold'>Success</p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className='mx-auto overflow-hidden md:max-w-7xl'>
                    <div className='grid mt-10 ml-2 mr-2 xl:mt-0 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                        <div className='bg-geen-600 h-fit'>
                            <div>
                                <p className='font-bold text-[30px]'> Our Legal Assistance for Money Recovery</p>
                            </div>

                            <div className='pt-5'>
                                <p>
                                Welcome to Haisty Decrypt Law Organization, where our dedicated legal team is committed to helping victims of scams recover their hard-earned money. Our experienced attorneys specialize in navigating the complexities of financial fraud cases, employing effective legal strategies to ensure the best possible outcome for our clients.
                                </p>
                            </div> <br />

                            <div>
                                <p className='font-semibold'> Meet Our Legal Team</p>
                                <p>
                                    At Haisty Decrypt, we take pride in our team of highly qualified and experienced attorneys who are passionate about advocating for victims of scams. Our legal experts bring a wealth of knowledge and skills to the table, ensuring that your case is handled with the utmost professionalism and dedication.
                                </p>
                            </div>

                            <div className="mt-8">
                            <a href="/law" className="">
                                <button className="learn__x1">Learn More</button>
                            </a>
                        </div>
                        </div>
                        <div className='bg-green-600 h-fit'>
                            <div>
                                <img src="https://cclawtn.com/wp-content/uploads/2022/11/2019-Best-Lawyers-Group-Photo.8.18.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>


            <div className="bg-[#f7f7f8] h-fit" id="Review">
            <motion.div className="pt-[6%]"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center">
                    <p className="font-semibold text-[#FF0102] uppercase">TOP Testimonies</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold text-center">Reviews</p>
                </motion.div>
                <div className='mx-auto overflow-hidden  md:max-w-7xl'>
                        <Review/>
                </div>




                <motion.div className="pt-[6%]"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ delay: 0.2, duration: 0.5 }}              
            variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
            }}>
                <div className="flex justify-center" id='FAQ'>
                    <p className="font-semibold text-[#ff0102] uppercase">frequently asked Questions</p>
                </div>
            </motion.div>

                <motion.div className="flex justify-center"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ delay: 0.3, duration: 0.5 }}              
                variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
                }}>
                    <p className="text-[46px] font-semibold text-center">F.A.Q</p>
                </motion.div>

                <div className="max-w-md mx-auto  overflow-hidden md:max-w-6xl mt-[5%] pb-[5%] pl-3 pr-2">
                        <div className=" grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5">
                            <div className="bg-[f60505] h-fit overflow-hidden">
                            <Accordion className="">
                                <AccordionSummary expandIcon={<AddIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                    <p className="text-[#030d26] font-semibold">Refund Policy</p>
                                </AccordionSummary>
                                <AccordionDetails>
                                We are dedicated to delivering exceptional results and meeting our clients' expectations. While we have confidence in 
                                our ability to complete the task at hand, we understand that unforeseen circumstances can sometimes affect our 
                                ability to fulfill our promises. Should we be unable to fulfill the task, rest assured that we promptly issue a 
                                refund. This demonstrates our unwavering commitment to ensuring our clients' satisfaction and fostering trust in 
                                our brand. Transparency and fairness are fundamental principles in all our interactions, and our terms and conditions 
                                are designed to provide clarity and protection for both our clients and our team. We remain devoted to delivering outstanding service and value to our clients, continually refining our processes to exceed expectations.
                                </AccordionDetails>
                            </Accordion>    

                                <div className="pt-5">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<AddIcon />}
                                             aria-controls="panel1a-content"  id="panel1a-header" >
                                            <p className="text-[#030d26] font-semibold">What services does Haistydecrypts offer to scam victims?</p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        Haistydecrypts provides a range of services to scam victims, including scam investigation, legal 
                                        assistance, and financial recovery. We work tirelessly to help you reclaim your lost funds and 
                                        provide guidance on how to prevent future scams.
                                        </AccordionDetails>
                                    </Accordion>    
                                </div>
                            </div>

                            <div className="bg-[f60505] h-fit">
                                <div className="">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<AddIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <p className="text-[#030d26] font-semibold"> How can I report a scam to  Haistydecrypts?</p>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        To report a scam to  Haistydecrypts, you have multiple convenient options. You can reach out to our dedicated support team through:

                                        <p className='pt-5'><span className='font-bold'>Contact Forms:</span> Use the provided <a href="/Contact">contact</a> forms to report the scam.</p>
                                        <p className='pt-2'><span className='font-bold'>Live Chat Support:</span  >We offer live chat support services, where you can connect with our team in 
                                        real-time for immediate assistance & we are always here to respond.</p>
                                        <p className='pt-2'><span className='font-bold'>Social Media:</span  > You can also contact us through our official social media handles</p>

                                        <p className='pt-5'>
                                        Rest assured, whenever you reach out, we will guide you through the process of documenting the scam and initiating a thorough 
                                        investigation to help you recover your money. Your financial well-being is our priority
                                        </p>
                                        </AccordionDetails>
                                    </Accordion>    
                                </div>

                                <div className="pt-5">
                                    <Accordion className="">
                                        <AccordionSummary expandIcon={<AddIcon />}
                                             aria-controls="panel1a-content"  id="panel1a-header" >
                                            <p className="text-[#030d26] font-semibold">Can  Haistydecrypts Assist with Online Investment Scam Victims?</p>
                                        </AccordionSummary>
                                            <AccordionDetails>
                                            Yes,  Haistydecrypts specializes in assisting victims of online investment scams. Whether you've 
                                            invested in a fraudulent scheme or cryptocurrency scam, we have the expertise and resources to 
                                            investigate, pursue legal action, and recover your funds.
                                           </AccordionDetails>
                                    </Accordion>    
                                </div>
                            </div>
                        </div>
                        
                    </div>
            </div>
            <section class="Contact__con mt-2">
            <div class="herox-content relative   text-left text-white z-10">
               <div className='mx-auto overflow-hidden md:max-w-7xl'>
                  <div className="grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-1 pb-10 pt-[5%]">
                     <div className='bggreen-600 h-fit'>
                        <div className='xl:pl-5 pl-2'>
                            <p className='font-bold xl:text-[1.8rem] text-[1.8rem]'> DO YOU WANT TO TALK TO US?</p>
                        </div>

                        <div className='xl:pl-5 pl-2'>
                            <p>
                            Have you lost something valuable or need assistance recovering lost assets? We're here to support you every step 
                            of the way. Feel free to reach out to us anytime – your concerns and inquiries matter to us, and we're dedicated 
                            to helping you regain what's rightfully yours!
                            </p>
                        </div>

                     </div>
                     <div className='bg-green-00 h-fit'>
                            <div className='xl:flex xl:justify-center flex justify-start mt-10'>
                            <button className='buttonx'>Contact Us</button>
                            </div>
                     </div>
                  </div>
               </div>
            </div>
        </section>

        <Footer/>
        </div>
    </>
  )
}

export default Home